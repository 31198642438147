export const isSocketIOConnected = (
  socket: React.RefObject<SocketIOClient.Socket | null>
): socket is React.RefObject<SocketIOClient.Socket> => {
  if (!socket.current) {
    return false;
  }

  if (!socket.current.connected) {
    console.log("Socket ", socket.current, " is not connected");
    return false;
  }

  return true;
};

export const isWebSocketConnected = (
  socket: React.RefObject<WebSocket | null>
): socket is React.RefObject<WebSocket> => {
  if (!socket.current) {
    return false;
  }

  if (socket.current.readyState !== WebSocket.OPEN) {
    console.log("Socket ", socket.current, " is not connected");
    return false;
  }

  return true;
};
